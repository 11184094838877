import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalbfmRequestModel } from '../../../../../src/app/modules/user-component/models/globalbfm-request.model';
import { GlobalMailBoxAddModel } from '../../models/mails/global-mailbox-add.model';
import { GlobalMailBoxEditModel } from '../../models/mails/global-mailbox-edit.model';

@Injectable({
  providedIn: 'root',
})

export class MailBoxService {

  constructor(
    private http: HttpClient) {
  }


  getmailBox(): Observable<any> {
    return this.http.get<any>(environment.api + 'mailbox/getmailboxpack');
  }

  getmailBoxWidthStatus(status:number): Observable<any> {
    return this.http.get<any>(environment.api + 'mailbox/getmailboxpack/' + status);
  }

  getSentMailBox(): Observable<any> {
    return this.http.get<any>(environment.api + 'mailbox/getsentmailboxpack');
  }

  updateMailBoxInvoice(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'mailbox/updatemailboxinvoice', payload);
  }

  getOrderTrack(): Observable<any> {
    return this.http.get<any>(environment.api + 'ordertrackv2');
  }

  getOrderTrackDetail(id:number): Observable<any> {
    return this.http.get<any>(environment.api + 'ordertrackdetail/' + id);
  }
  
  getBFMDataSummaryFromCartID(cartID:string, anonymousID:string) {
    return this.http.get(`https://api.shippn.com/api/bfm-basket/summary?anonymous_id=${anonymousID}&cart_id=${cartID}`
    )
  }

  getAnonymousID(userData:object) {
    return this.http.post(`https://bfm-gce-api.shippn.com/anonymous`,
      userData
    )
  }

  getBFMShoppingCart(model:{anonymous_id: any}) {
    return this.http.post(`https://bfm-gce-api.shippn.com/shopping_carts`,
    model
    )
  }

  addBFMDataFromCartID(userData:any, cartID:any, anonymousID:any) {
    return this.http.put(`https://bfm-gce-api.shippn.com/shopping_carts/${cartID}?anonymous_id=${anonymousID}`,
      userData
    )
  }

  createShoppingBasketOnDBWithoutToken(basketData:any){
    return this.http.post(`https://api.shippn.com/api/bfm/shopping-basket`, basketData)
  }

  getBFMDataFromCartID(cartID:string, anonymousID:string) {
    return this.http.get(`https://bfm-gce-api.shippn.com/shopping_carts/${cartID}?anonymous_id=${anonymousID}`,
    )
  }

  mailboxResize(params: any): Observable<any> {
    return this.http.get<any>(environment.api + 'mailbox/resize', params);
  }

  globalbfmAdd(model:GlobalbfmRequestModel): Observable<any> {
    return this.http.post<any>(environment.api + 'globalbfm/add',model);
  }

  globalbfmGet(): Observable<any> {
    return this.http.get<any>(environment.api + 'globalbfm/get');
  }

  globalMailBoxGet():Observable<any> {
    return this.http.get<any>(environment.api + 'globalmailbox/get');
  }

  globalMailBoxAdd(model:GlobalMailBoxAddModel): Observable<any> {
    return this.http.post<any>(environment.api + 'globalmailbox/add',model);
  }

  globalMailBoxDelete(model:{ProductIds:number[]}): Observable<any> {
    return this.http.put<any>(environment.api + 'globalmailbox/delete',model);
  }

  globalMailBoxEdit(model:GlobalMailBoxEditModel): Observable<any> {
    return this.http.put<any>(environment.api + 'globalmailbox/edit',model);
  }

  globalMailBoxGetAddress(model:{ProductIds:number[],Message:string}): Observable<any> {
    return this.http.post<any>(environment.api + 'globalmailbox/getAddress',model);
  }

  updatemailboxinvoice(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'mailbox/updatemailboxinvoice', payload);
  }

}
